<template>
  <div class="profile-code" v-if="!isProcessing">
    <div class="profile-code__body">
      <div class="profile-code__body__user">
        <v-img class="profile-code__body__user__icon" :src="user.icon" />
        <div class="profile-code__body__user__text">
          <p class="profile-code__body__user__text__name">{{ user.name }}</p>
          <p class="profile-code__body__user__text__position">{{ user.position }}</p>
        </div>
      </div>
      <qriously class="profile-code__body__qr" :value="url" :size="size" />
      <v-btn class="profile-code__body__share" text @click="share()">
        <v-icon class="profile-code__body__share__icon">share</v-icon>
        {{ isAvailableWebShareAPI ? '他の方法でシェアする' : 'URLをコピーする' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'profile_code',
  async mounted () {
    // ユーザ名に紐づくユーザーIDが存在するかの確認
    if (!this.uid) await this.$store.dispatch('uidUname/getUID', this.uname)
    if (!this.uid) {
      // 該当するユーザーIDが存在しないので404へ遷移させる
      this.$router.push({ name: 'notfound' })
      return
    }

    // ユーザー情報の取得
    if (!this.user) await this.$store.dispatch('users/getUser', this.uid)
    if (!this.user) {
      // ユーザー作成がまだな場合は404へ遷移させる
      this.$router.push({ name: 'notfound' })
      return
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} パラメータで指定されているユーザー名
     */
    uname () {
      return this.$route.params.uname
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['uidUname/uid'](this.uname)
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {String} ユーザープロフィールページのURL
     */
    url () {
      return window.location.protocol + '//' + window.location.host + '/profile/' + this.uname
    },
    /**
     * @return {Number} QRコードの横幅
     */
    size () {
      const width = screen.width * 0.8 > 240 ? 240 : screen.width * 0.8
      return width
    },
    /**
     * @return {Boolean} Web Share APIを使用できるかどうか
     */
    isAvailableWebShareAPI () {
      return navigator.share
    }
  },
  methods: {
    /**
     * URLのシェア
     */
    share () {
      if (this.isAvailableWebShareAPI) {
        // Web Share APIが使用できる場合
        navigator.share({
          title: this.user.name + 'のプロフィール',
          text: '#QRation',
          url: this.url
        })
      } else {
        this.$copyText(this.url).then(
          () => {
            // コピーしたらテロップを表示
            const msg = 'QRコードをコピーしました'
            this.$store.commit('setTelop', { show: true, msg: msg, type: 'success' })
          },
          () => {
            // コピーできなかったらエラーテロップを表示
            const msg = 'コピーできませんでした'
            this.$store.commit('setTelop', { show: true, msg: msg, type: 'error' })
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.profile-code {
  width: 100vw;
  max-width: $max_width;
  height: calc(100vh - #{$header_height} - #{$footer_height});
  margin: $header_height auto $footer_height auto;
  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 240px;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__user {
      width: 100%;
      text-align: center;
      &__icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        vertical-align: middle;
        background-color: $gray_color;
        border: 2px solid $gray_color;
        border-radius: 50%;
      }
      &__text {
        display: inline-block;
        max-width: calc(100% - 60px - 20px);
        margin-left: 20px;
        overflow: hidden;
        text-align: left;
        vertical-align: middle;
        &__name {
          margin: 0;
          overflow: hidden;
          font-size: 1.8rem;
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &__position {
          margin: 5px 0 0;
          overflow: hidden;
          font-size: 1.2rem;
          font-weight: bold;
          color: $orange_color;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &__qr {
      margin: 40px auto 0 auto;
    }
    &__share {
      display: block;
      margin: 40px auto 0 auto;
      font-size: 1.2rem;
      color: $dark_gray_color;
      &__icon {
        margin-right: 10px;
        font-family: $material-outlined;
        &.v-icon {
          font-size: 1.6rem;
          color: $dark_gray_color;
        }
      }
    }
  }
}
</style>
